<template>
  <v-dialog v-model="dialog" width="1000px">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" dark v-on="on">Создать слоты</v-btn>
    </template>
    <v-container>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-text-field v-model="slot.dates" label="Даты" outlined readonly clearable></v-text-field>
              <v-date-picker v-model="slot.dates" multiple :first-day-of-week="1"></v-date-picker>
            </v-col>
            <v-col>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="slot.channel_id"
                    :items="channels"
                    item-text="name"
                    item-value="id"
                    label="Наш канал"
                    outlined
                    clearable
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <AdPostSelector v-model="slot.ad_post_id"></AdPostSelector>
                </v-col>
                <v-col cols="6">
                  <AdChannelSelector :value.sync="slot.ad_channel_id" @change="adChannelChanged"></AdChannelSelector>
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model.number="slot.sum"  label="Сумма" outlined hide-details></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model.number="slot.amount" label="Кол-во" outlined hide-details></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete v-model="slot.payment_method" :items="paymentMethods" label="Способ оплаты" outlined hide-details />
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="slot.payment_details" label="Реквизиты" outlined></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" :loading="saveLoading" @click="save">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>

  </v-dialog>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import store from "@/store"
import AdPostSelector from "@/components/AdPostSelector"
import AdChannelSelector from "@/components/AdChannelSelector"
import httpClient from "@/services/http.service"

export default {
  name: "CreateSlots",
  components:{
    AdPostSelector, AdChannelSelector
  },
  setup() {
    const saveLoading = ref(false)
    const dialog = ref(false)
    const slot = ref({amount: 1, dates: []})
    const channels = computed(() => store.state.channels)
    const adPosts = computed(() => store.state.adPosts)
    const adChannels = computed(() => store.state.adChannels)
    const price = computed(() => Math.ceil(slot.value.sum / slot.value.amount))
    const paymentMethods = computed(() => store.getters.paymentMethods)
    const publications = computed(() => store.state.publications)

    //watch slot dates
    watch(slot, (val) => {
      console.log('slot changed!', val)
      if (!val.dates)return
      if (val.dates.length > slot.value.amount || !slot.value.amount){
        slot.value.amount = val.dates.length
      }
    }, {deep: true})

    const adChannelChanged = () =>{
      const lastPrice = adChannels.value.find(x=>x.id === slot.value.ad_channel_id).price || 0
      console.log('last price: ', lastPrice)
      if(lastPrice){
        slot.value.sum = lastPrice
      }
    }

    const createPayment = async () => {
      httpClient.post(`ad/payments`, {status: 'New', amount: slot.value.sum, payment_method: slot.value.payment_method,
        payment_details: slot.value.payment_details, ad_channel_id: slot.value.ad_channel_id, ad_post_id: slot.value.ad_post_id, channel_id: slot.value.channel_id
      })
        .then(({data}) => {
          slot.value.payment_id = data.id
          return data.id
        })
        .catch(() => {
          saveLoading.value = false
        })
    }

    const createSlots = async () => {
      const promises = []
      for (let i = 0; i < slot.value.amount; i++){
        let copy = {...slot.value}
        copy.planned_at = slot.value.dates[i] || ''
        copy.planned_at_time = '12:00'
        copy.price = price.value
        copy.status = 'Запланирован'
        promises.push(httpClient.post('ad/slots', copy)
          .then(({data}) => {
            store.dispatch('add', {mutation: 'updateAdSlot', payload: data})
          })
          .catch(() => {
            saveLoading.value = false
          }))
      }
      await Promise.all(promises).then(() => {
        saveLoading.value = false
        dialog.value = false
      })
    }

    const save = async () => {
      saveLoading.value = true
      await createPayment().then(() => {
        createSlots()
      })
    }

    return {
      dialog, paymentMethods, price, saveLoading,
      channels, adChannels, adPosts,
      slot,
      save, adChannelChanged,
    }
  }
}
</script>

<style scoped>

</style>
