<template>
  <v-autocomplete
    v-bind="props"
    v-model='valueLocal'
    item-text='name'
    item-value='id'
    :items="posts"
    @change="change"
  >
    <template v-slot:item="{ item }">
      <v-list-item-avatar v-if="favPosts.some(x=>x===item.id)"><v-icon small color="primary">{{ mdiStar }}</v-icon></v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
        <v-list-item-subtitle>{{countPublications(item)}} закупов, {{getDaysSinceLastAd(item)}}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="grey--text text--darken-1">
            Нет постов
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { computed } from '@vue/composition-api'
import store from '@/store'
import { mdiStar } from '@mdi/js'

export default {
  name: 'AdPostSelector',
  props: {
    post: {type: String, default: null},
    items: {type: Array, default: () => []},
    label: {type: String, default: 'Пост'},
    clearable: {type: Boolean, default: true},
    outlined: {type: Boolean, default: true},
    dense: {type: Boolean, default: false},
    itemText: {type: String, default: 'name'},
    itemValue: {type: String, default: 'id'},
    multiple: {type: Boolean, default: false},
    chips: {type: Boolean, default: false},
    deletableChips: {type: Boolean, default: false},
    returnObject: {type: Boolean, default: false},
    hideDetails: {type: Boolean, default: true},
  },
  setup(props, {emit}) {
    const favPosts = computed(() => store.state.favPosts)
    const posts = computed(() => store.state.adPosts.filter(x=>x.is_active).sort((a, b) => (favPosts.value.some(x=>x===a.id) ? -1 : 1)))

    const valueLocal = computed({
      get() {
        return props.post
      },
      set(val) {
        emit('input', val)
      }
    })

    const getDaysSinceLastAd = post => {
      if (!post.publications.length) return 'нет'
      const pubs = [...post.publications] //такая хрень, чтобы не мутировать channel, а то vue ругается на бесконечный рендер
      const lastAd =pubs.sort((a, b) => (a.id > b.id ? -1 : 1))[0]
      return Math.floor((new Date() - new Date(lastAd.planned_at)) / (1000 * 60 * 60 * 24)) + ' дн. назад'
    }

    const countPublications = post => {
      return store.state.adPublications.filter(x=>x.ad_post_id===post.id).length
    }

    const change = (val) => {
      emit('change', val)
    }

    return {
      valueLocal,
      props,
      favPosts,
      posts,
      getDaysSinceLastAd, countPublications,
      change,
      mdiStar,
    }
  },
}
</script>

<style scoped>

</style>
