<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-date-picker v-model="slot.planned_at" :first-day-of-week="1"></v-date-picker>
          </v-col>
          <v-col>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="slot.channel_id"
                  :items="channels"
                  :readonly="readonly"
                  item-text="name"
                  item-value="id"
                  label="Наш канал"
                  outlined
                  clearable
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="6">
                <AdPostSelector v-model="slot.ad_post_id" :readonly="readonly"></AdPostSelector>
              </v-col>
              <v-col cols="6">
                <AdChannelSelector :value.sync="slot.ad_channel_id" :readonly="readonly"></AdChannelSelector>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model.number="slot.price" readonly label="Стоимость" :messages="['Можно только смотреть']" outlined></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="slot.publication_id">
          <v-checkbox v-model="slot.update_publication" :true-value="1" :false-value="0" label="Обновить публикацию (если ещё не опубликована)"></v-checkbox>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="slot.id" color="primary" @click="save">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>

</template>

<script>
import { ref, computed } from '@vue/composition-api'
import {useVModel} from "@vueuse/core"
import store from "@/store"
import AdPostSelector from "@/components/AdPostSelector"
import AdChannelSelector from "@/components/AdChannelSelector"
export default {
  name: "EditSlot",
  components:{
    AdPostSelector, AdChannelSelector
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, {emit}) {
    const slot = useVModel(props, 'item', emit)

    const channels = computed(()=>store.state.channels)
    const adChannels = computed(()=>store.getters.adChannels)
    const adPosts = computed(()=>store.getters.adPosts)

    const readonly = computed(()=>slot.value.status === 'Опубликован')

    const save = () => {
      httpClient.put('/api/ads/slots/' + slot.value.id, slot.value)
        .then((response) => {
          store.dispatch('add', 'updateAdSlot', response.data)
        })
    }

    return {
      readonly,
      channels, adChannels, adPosts,
      slot,
      save,

    }
  }
}
</script>

<style scoped>

</style>
