<template>
  <perfect-scrollbar>
    <v-dialog v-model="d" max-width="1000px">
      <edit-slot :item="editSlot" @close="close()"></edit-slot>
    </v-dialog>
  </perfect-scrollbar>
</template>

<script>
import EditSlot from "@/views/ads/slots/EditSlot";
import { ref, computed } from '@vue/composition-api'
import {useVModel} from "@vueuse/core";
export default {
  name: "EditSlotDialog",
  components: {
    EditSlot
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        id: 0,
        dates: [],
      })
    },
    dialog:{
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {
    const d = useVModel(props, 'dialog', emit)
    const editSlot = useVModel(props, 'value', emit)
    const close = ()=>{
      emit('close')
    }

    return {
      editSlot, d,
      close
    }
  }
}
</script>

<style scoped>

</style>
