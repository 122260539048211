<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <!--                календарь и фильтр по дате-->
              <v-col cols="6">
                <div class="d-flex justify-start align-start">
                  <v-date-picker
                    v-model="filters.dates"
                    label="Дата"
                    multiple
                    outlined
                    hide-details
                    range
                  />
                  <v-list dense>
                    <v-list-item-group v-model="filters.dateSettings" color="primary">
                      <v-list-item :value="'tomorrow'">
                        <v-list-item-content>
                          <v-list-item-title class="title">Завтра</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item :value="'today'">
                        <v-list-item-content>
                          <v-list-item-title class="title">Сегодня</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item :value="'yesterday'">
                        <v-list-item-content>
                          <v-list-item-title class="title">Вчера</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item :value="'week'">
                        <v-list-item-content>
                          <v-list-item-title class="title">На этой неделе</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item :value="'month'">
                        <v-list-item-content>
                          <v-list-item-title class="title">В этом месяце</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item :value="'previous_month'">
                        <v-list-item-content>
                          <v-list-item-title class="title">В прошлом месяце</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider />
                      <v-list-item :value="'deny'">
                        <v-list-item-avatar><v-icon>{{mdiClose}}</v-icon></v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="title">Без фильтра</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="filters.status"
                  :items="statuses"
                  label="Статус"
                  outlined hide-details
                ></v-select>
              </v-col>
              <v-col cols="3">
                <AdChannelSelector
                  :value.sync="filters.ad_channel_id"
                  label="Источник"
                  outlined
                  hide-details
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="payments"
                  :items-per-page="50"
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Оплаты</v-toolbar-title>
                      <v-divider
                        class="mx-4"
                        inset
                        vertical
                      ></v-divider>
                      <v-spacer></v-spacer>
                      <create-slots></create-slots>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.created_at="{ item }">
                    {{ formatDate(item.created_at) }}
                  </template>
                  <template v-slot:item.slots_total="{ item }">
                    {{ item.slots.length }}
                  </template>
                  <template v-slot:item.slots_free="{ item }">
                    {{ item.slotsFree.length }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <edit-slot-dialog v-model="editItem" :dialog.sync="editDialog" @close="editDialog=false"></edit-slot-dialog>
  </v-container>
</template>

<script>
import { ref, computed } from "@vue/composition-api"
import store from "@/store"
import EditSlotDialog from "@/views/ads/slots/EditSlotDialog"
import CreateSlots from "@/views/ads/slots/CreateSlots"
import AdPostSelector from "@/components/AdPostSelector"
import AdChannelSelector from "@/components/AdChannelSelector"
import moment from "moment/moment";
import {watch} from "@vue/composition-api/dist/vue-composition-api"
import {mdiClose} from "@mdi/js"

export default {
  name: "AdPayments",
  components:{
    AdPostSelector, AdChannelSelector,
    EditSlotDialog, CreateSlots,
  },
  setup() {
    const defaultItem = ref({id: 0, update_publication: 0})
    const editItem = ref({id: 0, update_publication: 0})
    const editDialog = ref(false)
    const headers = ref([
      { text: "Канал", value: "channel.name"},
      { text: "Источник", value: "adChannel.name" },
      // { text: 'Пост', value: 'adPost.name'},
      { text: "Стоимость", value: "amount" },
      // {text: 'Статус', value: 'status'},
      {text: 'Дата', value: 'created_at'},
      {text: 'Слотов всего', value: 'slots_total', sortable: false},
      {text: 'Слотов свободно', value: 'slots_free', sortable: false},
    ])
    const filters = ref({
      channel_id: null,
      ad_channel_id: null,
      status: null,
      dates: null,
      dateSettings: null,
    })

    watch(() => filters.value.dateSettings, value => {
      console.log('watch filterByDateSettings', value)
      let date = false
      if (value === 'month') {
        date = [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
      }else if (value === 'week') {
        // date = [1, 0]
        date = [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')]
      }else if (value === 'today') {
        date = [moment().endOf('day').format('YYYY-MM-DD')]
      }else if (value === 'yesterday') {
        date = [moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD'), moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD')]
      } else if (value === 'tomorrow') {
        date = [moment().add(1, 'days').startOf('day').format('YYYY-MM-DD')]
      } else if (value === 'previous_month') {
        date = [moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')]
      } else if (value === 'next_month') {
        date = [moment().add(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().add(1, 'months').endOf('month').format('YYYY-MM-DD')]
      } else if (value === 'deny') {
        date = null
      }
      filters.value.dates = date ? date : null
    })



    const statuses = computed(()=>store.getters.adSlotStatuses)
    const allSlots = computed(()=>store.getters.adSlots)
    const slots = computed(()=>{
      let s = allSlots.value
      if (filters.value.channel_id) s = s.filter(x => x.channel_id === filters.value.channel_id)
      if (filters.value.ad_channel_id) s = s.filter(x => x.ad_channel_id === filters.value.ad_channel_id)
      if (filters.value.status) s = s.filter(x => x.status === filters.value.status)
      return s
    })
    const allPayments = computed(()=>store.getters.adPayments)

    const payments = computed(()=>{
      let p = allPayments.value
      if (filters.value.channel_id) p = p.filter(x => x.channel_id === filters.value.channel_id)
      if (filters.value.ad_channel_id) p = p.filter(x => x.ad_channel_id === filters.value.ad_channel_id)
      if (filters.value.dates) {
        if (filters.value.dates.length===1) {
          p = p.filter(x=>moment(x.created_at).isSame(filters.value.dates[0], 'day'))
        } else {
          p = p.filter(x=>moment(x.created_at).isBetween(filters.value.dates[0], filters.value.dates[1], 'day', '[]'))
        }
      }
      return p
    })

    const createSlot = ()=>{
      editItem.value = defaultItem.value
      editDialog.value = true
    }

    const formatDate = date => moment(date).format('DD.MM.YY')

    return {
      allPayments, payments,
      statuses,
      filters,
      slots, headers,
      editItem, editDialog,
      createSlot, formatDate,
      mdiClose,
    }
  },
}
</script>

<style scoped>

</style>
